<template>
  <section class="invoice-add-wrapper">
    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      v-b-modal.modal-xl
      variant="outline-primary"
    >
      Add Venue
    </b-button>

    <b-modal
      v-model="dialog1"
      id="modal-xl"
      ok-only
      ok-title="close"
      centered
      size="lg"
      title="Add Venue"
    >
      <b-card-text>
        <b-row class="invoice-add">
          <b-col cols="12" xl="12" md="12">
            <b-form enctype="multipart/form-data" @submit.prevent>
              <b-card no-body class="invoice-preview-card">
                <b-card-body class="invoice-padding form-item-section">
                  <div ref="form" class="repeater-form">
                    <b-row ref="row" class="pb-2">
                      <b-form @submit.prevent>
                        <b-row>
                          <b-col cols="12">
                            <div></div>
                          </b-col>
                          <b-col cols="12">
                            <b-form-group label="title">
                              <b-input-group class="input-group-merge">
                                <b-form-input
                                  id="title"
                                  v-model="title"
                                  type="text"
                                  placeholder="title"
                                />
                              </b-input-group>
                              <b-alert
                                v-if="check.title == true"
                                variant="danger"
                                show
                              >
                                <span>Please Enter</span>
                              </b-alert>
                            </b-form-group>
                          </b-col>
                          <b-col cols="6">
                            <ThailandAutoComplete
                              v-model="district"
                              type="district"
                              @select="select"
                              label="ตำบล"
                              color="#42b883"
                              size="medium"
                              placeholder="ตำบล..."
                            />
                          </b-col>
                          <b-col cols="6">
                            <ThailandAutoComplete
                              v-model="amphoe"
                              type="amphoe"
                              @select="select"
                              label="อำเภอ"
                              size="medium"
                              placeholder="อำเภอ..."
                            />
                          </b-col>
                          <b-col cols="6">
                            <ThailandAutoComplete
                              v-model="province"
                              type="province"
                              @select="select"
                              label="จังหวัด"
                              size="medium"
                              color="#35495e"
                              placeholder="จังหวัด..."
                            />
                          </b-col>
                          <b-col cols="6">
                            <ThailandAutoComplete
                              v-model="zipcode"
                              type="zipcode"
                              @select="select"
                              label="รหัสไปรษณีย์"
                              size="medium"
                              color="#00a4e4"
                              placeholder="รหัสไปรษณีย์..."
                            />
                          </b-col>

                          <b-col cols="6">
                            <b-form-group label="ละติจูด">
                              <b-input-group class="input-group-merge">
                                <b-form-input
                                  id="latitude"
                                  v-model="latitude"
                                  type="number"
                                  placeholder="ละติจูด"
                                />
                              </b-input-group>
                            </b-form-group>
                          </b-col>
                          <b-col cols="6">
                            <b-form-group label="ลองจิจูด">
                              <b-input-group class="input-group-merge">
                                <b-form-input
                                  id="longitude"
                                  v-model="longitude"
                                  type="number"
                                  placeholder="ลองจิจูด"
                                />
                              </b-input-group>
                            </b-form-group>
                          </b-col>
                          <b-col cols="12">
                            <b-form-group label="howto">
                              <b-input-group class="input-group-merge">
                                <b-form-input
                                  id="howto"
                                  v-model="howto"
                                  type="text"
                                  placeholder="howto"
                                />
                              </b-input-group>
                            </b-form-group>
                          </b-col>

                          <br /><br />
                          <b-col cols="12">
                            <!-- image -->
                            <div v-if="logo == null">
                              <b-row class="match-height">
                                <b-col>
                                  <b-container fluid class="p-1 bg-dark">
                                    <b-row>
                                      <b-col>
                                        <b-img
                                          thumbnail
                                          fluid
                                          :src="
                                            require('@/assets/images/img/cloud-computing.png')
                                          "
                                        />
                                      </b-col>
                                    </b-row>
                                  </b-container>
                                  <b-form-file
                                    placeholder="อัพโหลดรูป"
                                    ref="refInputEl"
                                    type="file"
                                    accept=".jpeg,.png,.jpg,GIF"
                                    :hidden="true"
                                    @change="onFileChange"
                                  />
                                </b-col>
                              </b-row>
                            </div>
                            <div class="pa-5" id="Box" v-else>
                              <b-row class="match-height">
                                <b-col>
                                  <b-container fluid class="p-1 bg-dark">
                                    <b-row>
                                      <b-col>
                                        <b-img thumbnail fluid :src="logo" />
                                      </b-col>
                                    </b-row>
                                  </b-container>
                                  <b-form-file
                                    placeholder="เปลี่ยนรูปภาพ"
                                    ref="refInputEl"
                                    type="file"
                                    accept=".jpeg,.png,.jpg,GIF"
                                    :hidden="true"
                                    @change="onFileChange"
                                  />
                                </b-col>
                              </b-row>
                            </div>
                            <!-- image -->
                          </b-col>

                          <!-- submit and reset -->
                          <b-col>
                            <br /><br />
                            <b-button
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              type="submit"
                              variant="primary"
                              class="mr-1"
                              @click="save()"
                            >
                              Submit
                            </b-button>
                            <b-button
                              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                              type="reset"
                              variant="outline-secondary"
                              @click="reset()"
                            >
                              Reset
                            </b-button>
                          </b-col>
                        </b-row>
                      </b-form>
                    </b-row>
                  </div>
                </b-card-body>
              </b-card>
            </b-form>
          </b-col>
        </b-row>
      </b-card-text>
    </b-modal>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BListGroupItem,
  BCardTitle,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BCardCode,
  BFormFile,
  BImg,
  BMedia,
  VBModal,
  BFormDatepicker,
  BCarousel,
  BCarouselSlide,
  BContainer,
  BFormText,
  BAlert,
} from "bootstrap-vue";
import ThailandAutoComplete from "vue-thailand-address-autocomplete";
import Swal from "sweetalert2";
import Ripple from "vue-ripple-directive";
import "animate.css";
import api from "@/api";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import vSelect from "vue-select";

export default {
  components: {
    ThailandAutoComplete,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BCardCode,
    BFormFile,
    BListGroupItem,
    BCardTitle,
    BImg,
    BMedia,
    VBModal,
    BFormDatepicker,
    BCarousel,
    BCarouselSlide,
    BContainer,
    BFormText,
    BAlert,
    quillEditor,
    vSelect,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      dialog1: null,
      title: null,
      logo: null,
      latitude: null,
      longitude: null,
      Filelogo: null,
      howto: null,
      district: "",
      amphoe: "",
      province: "",
      zipcode: "",

      check: {
        title: false,
        province: false,
        amphoe: false,
        district: false,
        zipcode: false,
      },
    };
  },
  methods: {
    select(address) {
      this.district = address.district;
      this.amphoe = address.amphoe;
      this.province = address.province;
      this.zipcode = address.zipcode;
    },
    onFileChange(e) {
      this.Filelogo = e.target.files[0];
      console.log("Filelogo", this.Filelogo);
      const image = e.target.files[0];
      this.logo = URL.createObjectURL(image); ///show
    },
    refresh() {
      this.$emit("loaddata", "loaddata");
    },
    save() {
      if (
        this.title &&
        this.province &&
        this.amphoe &&
        this.district &&
        this.zipcode
      ) {
        Swal.fire({
          title: "ยืนยันการบันทึกข้อมูล",
          text: "",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          showLoaderOnConfirm: true,
          preConfirm: () => {},
        }).then((result) => {
          if (result.isConfirmed) {
            let formData = new FormData();
            formData.append("Filelogo", this.Filelogo);
            formData.append("title", this.title);
            formData.append("province", this.province);
            formData.append("amphoe", this.amphoe);
            formData.append("district", this.district);
            formData.append("zipcode", this.zipcode);
            formData.append("latitude", this.latitude);
            formData.append("longitude", this.longitude);
            formData.append("howto", this.howto);

            api.post("/dashboard/venue/add", formData).then((response) => {
              console.log("response", response);
              if (response) {
                Swal.fire({
                  icon: "success",
                  title: "success",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.dialog1 = false;
                this.refresh();
                this.reset();
              }
            });
          }
        });
      } else {
        if (!this.title) {
          this.check.title = true;
          console.log("title null");
        }
        if (!this.province) {
          this.check.province = true;
          console.log("province null");
        }
        if (!this.amphoe) {
          this.check.amphoe = true;
          console.log("amphoe null");
        }
        if (!this.district) {
          this.check.district = true;
          console.log("district null");
        }
        if (!this.zipcode) {
          this.check.zipcode = true;
          console.log("zipcode null");
        }
        this.$swal({
          title: "Please complete the information.",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          showClass: {
            popup: "animate__animated animate__bounceIn",
          },
          buttonsStyling: false,
        });
      }
    },
    reset() {
      this.title = null;
      this.logo = null;
      this.province = null;
      this.amphoe = null;
      this.district = null;
      this.zipcode = null;
      this.latitude = null;
      this.longitude = null;
      this.check.title = false;
      this.check.province = false;
      this.check.amphoe = false;
      this.check.district = false;
      this.check.zipcode = false;
    },
  },
};
</script>
