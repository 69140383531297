<template>
  <div>
    <!-- modal -->
    <b-modal
      v-model="dialog"
      size="lg"
      title="Detail"
      ok-only
      ok-title="Exit"
      no-stacking
    >
      <b-card-text>
        <b-row class="invoice-add">
          <b-col cols="12" xl="12" md="12">
            <b-form enctype="multipart/form-data" @submit.prevent>
              <b-card no-body class="invoice-preview-card">
                <b-card-body class="invoice-padding form-item-section">
                  <div ref="form" class="repeater-form">
                    <b-row ref="row" class="pb-2">
                      <b-form @submit.prevent>
                        <b-row>
                          <b-col cols="12">
                            <b-form-group label="Category">
                              <v-select
                                v-model="category"
                                :dir="
                                  $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                "
                                label="title"
                                :options="option"
                                disabled
                              />
                            </b-form-group>
                          </b-col>
                          <b-col cols="12">
                            <b-form-group label="Campaign Name">
                              <b-input-group class="input-group-merge">
                                <b-form-input
                                  id="content_title"
                                  v-model="content_title"
                                  type="text"
                                  placeholder="name"
                                  disabled
                                />
                              </b-input-group>
                              <b-alert
                                v-if="check.content_title == true"
                                variant="danger"
                                show
                              >
                                <span>Please Enter</span>
                              </b-alert>
                            </b-form-group>
                          </b-col>

                          <b-col cols="12">
                            <!-- image -->
                            <div v-if="content_Image == null">
                              <b-row class="match-height">
                                <b-col>
                                  <b-container fluid class="p-1 bg-dark">
                                    <b-row>
                                      <b-col>
                                        <b-img
                                          thumbnail
                                          fluid
                                          :src="
                                            require('@/assets/images/img/cloud-computing.png')
                                          "
                                        />
                                      </b-col>
                                    </b-row>
                                  </b-container>
                                </b-col>
                              </b-row>
                            </div>
                            <div class="pa-5" id="Box" v-else>
                              <b-row class="match-height">
                                <b-col>
                                  <b-container fluid class="p-1 bg-dark">
                                    <b-row>
                                      <b-col>
                                        <b-img
                                          thumbnail
                                          fluid
                                          :src="content_Image"
                                        />
                                      </b-col>
                                    </b-row>
                                  </b-container>
                                </b-col>
                              </b-row>
                            </div>
                            <!-- image -->
                          </b-col>
                          
                          <b-col cols="12">
                            <b-form-group label="Content Detail">
                              <quill-editor
                                v-model="content_detail"
                                :options="snowOption"
                                disabled
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-form>
                    </b-row>
                  </div>
                </b-card-body>
              </b-card>
            </b-form>
          </b-col>
        </b-row>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BListGroupItem,
  BCardTitle,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BCardCode,
  BFormFile,
  BImg,
  BMedia,
  VBModal,
  BFormDatepicker,
  BCarousel,
  BCarouselSlide,
  BContainer,
  BFormText,
  BAlert,
} from "bootstrap-vue";
import Swal from "sweetalert2";
import Ripple from "vue-ripple-directive";
import "animate.css";
import api from "@/api";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import vSelect from "vue-select";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BCardCode,
    BFormFile,
    BListGroupItem,
    BCardTitle,
    BImg,
    BMedia,
    VBModal,
    BFormDatepicker,
    BCarousel,
    BCarouselSlide,
    BContainer,
    BFormText,
    BAlert,
    quillEditor,
    vSelect,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      id: null,
      dialog: null,
      content_Image: null,
      content_title: null,
      content_detail: null,
      check: {
        content_title: false,
        content_detail: false,
      },
      category: "ทั่วไป",
      option: [
        { title: "ทั่วไป" },
        { title: "อาหาร" },
        { title: "เทศกาล" },
        { title: "ติดเทรน" },
        { title: "ยอดฮิด" },
      ],
    };
  },
  methods: {
    async showModaldata(id) {
      console.log("id", id);
      const params = {
        id: id,
      };
      // get date axios
      api.post("/contentget", params).then((response) => {
        console.log(response.data);
        console.log((this.items = response.data.codes));
        console.log((this.totalRows = response.data.total));
        this.amount = "จำนวนโค้ดทั้งหมด " + this.totalRows + " โค้ด";
        for (let item of response.data.data) {
          (this.content_Image = item.content_Image),
            (this.content_title = item.content_title),
            (this.content_detail = item.content_detail)(
              (this.category = item.category)
            );
        }
      });
      this.dialog = true;
    },
  },
};
</script>

