<template>
  <b-card title="Venue" no-body>
    <b-card-body>
      <div class="d-flex justify-content-between flex-wrap">
        <!-- sorting  -->
        <b-form-group
          label="Sort"
          label-size="sm"
          label-align-sm="left"
          label-cols-sm="2"
          label-for="sortBySelect"
          class="mr-1 mb-md-0"
        >
          <b-input-group size="sm">
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              :options="sortOptions"
            >
              <template #first>
                <option value="">none</option>
              </template>
            </b-form-select>
            <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy">
              <option :value="false">Asc</option>
              <option :value="true">Desc</option>
            </b-form-select>
          </b-input-group>
        </b-form-group>

        <!-- filter -->
        <b-form-group
          label="Filter"
          label-cols-sm="2"
          label-align-sm="left"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <b-form-group>
          <addvenue @loaddata="loaddata"></addvenue>
          <editvenue @loaddata="loaddata" ref="modal"></editvenue>
          <showvenue ref="modalshow"></showvenue>
        </b-form-group>
        <b-button
          variant="outline-primary"
          @click="downloadCSVData()"
        >
          Export
        </b-button>
      </div>
    </b-card-body>

    <b-table
      striped
      hover
      responsive
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPage"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
    >
      <!-- Column: User -->
      <template #cell(logo)="data">
        <b-media vertical-align="center" @click="click(data.item.id)">
          <template #aside>
            <b-avatar size="32" :src="data.item.logo" />
          </template>
        </b-media>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <b-dropdown variant="link" no-caret>
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <b-dropdown-item @click="edit_data(data.item.id)">
            <feather-icon icon="EditIcon" />
            <span class="align-middle ml-50">Edit</span>
          </b-dropdown-item>

          <b-dropdown-item @click="delete_data(data.item.id)">
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50">Delete</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BMedia,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCardBody,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import api from "@/api";
import addvenue from "./AddVenue.vue";
import editvenue from "./EditVenue.vue";
import showvenue from "./ShowVenue.vue";
export default {
  components: {
    addvenue,
    editvenue,
    showvenue,
    BCard,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BMedia,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      // csvdata: [
      //   [1, 2, 3],
      //   [4, 5, 6],
      //   [7, 8, 9],
      // ],
      perPage: 10,
      pageOptions: [10, 20, 30],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        {
          key: "id",
          label: "Id",
        },
        {
          key: "logo",
          label: "LOGO",
          sortable: false,
        },
        { key: "title", label: "Venue", sortable: true },
        { key: "hotspots", label: "Gateway / AP", sortable: true },
        { key: "actions" },
      ],
      /* eslint-disable global-require */
      items: [],
      /* eslint-disable global-require */
      status: [
        {
          1: "Current",
          2: "Professional",
          3: "Rejected",
          4: "Resigned",
          5: "Applied",
        },
        {
          1: "light-primary",
          2: "light-success",
          3: "light-danger",
          4: "light-warning",
          5: "light-info",
        },
      ],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    // Set the initial number of items
    this.loaddata();
    // this.totalRows = this.items.length
  },
  methods: {
    delete_data(id) {
      const params = {
        id: id,
      };
      console.log("id :>> ", id);
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          console.log("delete", id);
          const params = {
            id: id,
          };
          api.post("/dashboard/venue/venuedel", params).then((response) => {
            console.log(response);
            this.loaddata();
            this.$swal({
              icon: "success",
              title: "Deleted!",
              text: "Your file has been deleted.",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          });
        }
      });
    },
    edit_data(id) {
      this.$refs.modal.showModaledit(id);
    },
    show_data(id) {
      this.$refs.modalshow.showModaldata(id);
    },
    click(id) {
      localStorage.setItem("venue", id);
      this.$router.push("/venue/reports/people");
    },
    loaddata() {
      const params = {};
      api
        .get("/admin/listvenue", params)
        .then((response) => {
          this.items = response.data.data;
          this.totalRows = response.data.total;
          console.log(response.data);
        })
        .catch((error) => {
          // router.push('/pages/not-authorized');
        });
    },

    downloadCSVData() {
      var csvdata = new Array();
      var i = 0;
      this.items.forEach(function (arrayItem) {
        csvdata[i] = new Array();
        csvdata[i].push(arrayItem.title);
        csvdata[i].push(arrayItem.hotspots);
        csvdata[i].push(arrayItem.province);
        csvdata[i].push(arrayItem.district);
        csvdata[i].push(arrayItem.amphoe);
        csvdata[i].push(arrayItem.latitude);
        csvdata[i].push(arrayItem.longitude);
        i++;
      });

      // console.log("csvdata :>> ", csvdata);
      let csv = "title,hotspots,province,district,amphoe,latitude,longitude\n";
      csvdata.forEach((row) => {
        csv += row.join(",");
        csv += "\n";
      });
      var universalBOM = "\uFEFF";
      const anchor = document.createElement("a");
      anchor.href =
        "data:text/csv;charset=utf-8," + encodeURIComponent(universalBOM + csv);
      anchor.target = "_blank";
      anchor.download = "venue.csv";
      anchor.click();
    },

    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
